import { Dimensions } from 'react-native'

export const itunesItemId = '1659288235'
export const androidPackageName = 'com.georgejose.journal'

export const STATS_PADDING_OR_MARGIN_HORIZONTAL = 8
export const NUM_SQUARES_PER_ROW = 16

export const HEATMAP_CONTAINER_MARGIN = 10
export const SQUARE_MARGIN = 1
export const screenWidth = Dimensions.get('screen').width
export const squareWidthAndHeight =
  (screenWidth -
    2 * STATS_PADDING_OR_MARGIN_HORIZONTAL -
    2 * NUM_SQUARES_PER_ROW -
    2 * HEATMAP_CONTAINER_MARGIN -
    NUM_SQUARES_PER_ROW * SQUARE_MARGIN) /
  NUM_SQUARES_PER_ROW

export const AMPLITUDE_KEY = 'be13299605f4eac256e6c5079ebbf09a'
