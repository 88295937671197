import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import icon from './assets/icon.png'
import screenshots from './assets/web-screenshots.png'
import downloadOnGooglePlay from './assets/downloadOnGooglePlay.png'
import downloadOnAppStore from './assets/downloadOnAppStore.png'
import { init, track } from '@amplitude/analytics-browser'
import { AMPLITUDE_KEY } from './src/constants'

init(AMPLITUDE_KEY)

const IconAndName = () => {
  const size = 60
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Image
        source={icon}
        style={{ width: size, height: size, marginRight: 12 }}
      />
      <View>
        <Text style={[styles.text, { fontSize: 24, fontWeight: '900' }]}>
          A Journal A Day
        </Text>
        <Text style={[styles.text, { color: '#eaeaea', fontSize: 18 }]}>
          Daily Journal Entries
        </Text>
      </View>
    </View>
  )
}

const Header = () => {
  return (
    <View
      style={{
        padding: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <IconAndName />
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            'mailto:support@ajournaladay.com?subject=Feedback&body=%0D%0A%0D%0ADebug%20info%0D%0Asource%3A%20web%0D%0A'
          )
        }
      >
        <Text style={[{ fontSize: 18, fontWeight: 'bold' }, styles.text]}>
          Contact
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const Content = () => {
  const ratio = 15609 / 6280
  const { width: screenWidth, height: screenHeight } = useWindowDimensions()

  const imageMaxWidth = 0.9 * screenWidth
  const imageMaxHeight = 0.6 * screenHeight

  const width = 90
  const height = Math.min(width / ratio, 70)

  console.log({ height, width })

  return (
    <View
      style={{
        alignSelf: 'center',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 12,
        width: '90%',
      }}
    >
      <Image
        source={screenshots}
        style={{
          height: `${height}vw`,
          width: `${width}vw`,
          maxWidth: imageMaxWidth,
          maxHeight: imageMaxHeight,
          resizeMode: 'contain',
          marginBottom: 50,
          justifyContent: 'center',
        }}
      />
      <Text style={[styles.text, styles.keyword]}>
        <Text style={styles.highlight}>Secure</Text>,{' '}
        <Text style={styles.highlight}>password protected</Text>,{' '}
        <Text style={styles.highlight}>private</Text> daily journal entries,
      </Text>
      <Text style={[styles.text, styles.keyword]}>
        where all your data stays{' '}
        <Text style={styles.highlight}>on your device.</Text>
      </Text>
      <View
        style={[
          styles.appStoreIcons,
          {
            flexDirection: screenWidth > 500 ? 'row' : 'column',
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            track('download_app_pressed', { type: 'ios_app_store' })
            Linking.openURL(
              'https://apps.apple.com/us/app/a-journal-a-day/id1659288235'
            )
          }}
        >
          <Image source={downloadOnAppStore} style={styles.appStore} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            track('download_app_pressed', { type: 'google_play_store' })
            Linking.openURL(
              'https://play.google.com/store/apps/details?id=com.georgejose.journal'
            )
          }}
        >
          <Image source={downloadOnGooglePlay} style={styles.appStore} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

const App = () => {
  return (
    <View
      style={{ backgroundColor: '#130f29', width: '100vw', height: '100vh' }}
    >
      <Header />
      <Content />
    </View>
  )
}

export default App

const styles = StyleSheet.create({
  text: { color: '#ffffff' },
  keyword: {
    fontSize: 30,
    fontWeight: '900',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  appStore: {
    width: 200,
    height: 70,
    resizeMode: 'cover',
    marginHorizontal: 8,
    marginVertical: 4,
  },
  appStoreIcons: {
    marginTop: 24,
    justifyContent: 'center',
  },
  highlight: {},
})
